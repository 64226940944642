import React, {useEffect, useState} from 'react';
import AppHeader from '../components/header/AppHeader';
import {BrowserRouter, Route, Switch, Redirect, useHistory} from 'react-router-dom';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import NotFoundPage from './NotFoundPage';
import HomePage from './HomePage';
import PageWrapper from '../components/common/PageWrapper';
import UserContext from '../context/UserContext';
import {getUserFromStorage, removeUserFromStorage, saveUserToStorage, UserRoles} from '../utils/UserUtils';
import ProfilePage from './ProfilePage';
import LogoutPage from './LogoutPage';
import SessionsPage from './SessionsPage';
import {isTokenIssue, tryRefreshTokens} from '../utils/ApiUtils';
import CreateRequestPage from './CreateRequestPage';
import RequestListPage from './RequestListPage';
import * as styles from './App.css';
import RequestPage from './RequestPage';
import EmailConfirmationPage from './EmailConfirmationPage';
import {getUser} from '../api/User';
import LoadingIndicator from '../components/common/LoadingIndicator';

function App() {
    const routerRef = React.useRef();
    const [user, setUser] = useState(getUserFromStorage());
    const [loading, setLoading] = useState(false);
    let tokensRefreshPromise = false;
    const getUserFromServer = ()=>{
        setLoading(true);
        return getUser().then(userFromServer => {
            setLoading(false);
            if (userFromServer?.id){
                saveUser(Object.assign({accessToken: user.accessToken,  refreshToken: user.refreshToken}, userFromServer));
            }
        }).catch(error=>{
            setLoading(false);
            if (isTokenIssue(error.message))
                throw error;
            else{
                alert('Не удалось получить данные профиля');
            }
        });
    };
    useEffect(async ()=>{
        try{
            if(user.id){
                await getUserFromServer();
            }
        }catch (error) {
            tryRefreshTokensAndRepeatAction(error.message, ()=>getUserFromServer());
        }
    }, []);
    const saveUser = (userData)=>{
        userData = {...userData};
        setUser(userData);
        if(userData){
            saveUserToStorage(userData);
        }else{
            removeUserFromStorage();
        }
    };
    const tryRefreshTokensAndRepeatAction = async (errorMessage, actionToRepeat) => {
        try{
            if(!tokensRefreshPromise){
                tokensRefreshPromise = tryRefreshTokens(errorMessage);
            }
            const tokens = await tokensRefreshPromise;
            if (tokens){
                saveUser(Object.assign(user, {accessToken: tokens.access, refreshToken: tokens.refresh}));
tokensRefreshPromise = null;
                return actionToRepeat();
            }else{
                tokensRefreshPromise = null;
            }
        }catch (error) {
            saveUser(false);
            routerRef.current.history.push('/login');
        }
    };
    return (
        <div className={styles.app}>
            <LoadingIndicator shown={loading}/>
            <UserContext.Provider value={{user, saveUser, tryRefreshTokensAndRepeatAction}}>
                <BrowserRouter ref={routerRef} basename={process.env.PUBLIC_URL}>
                    <Switch>
                        <Route exact path="/" >
                            {user?.id ? <HomePage/> : <Redirect to="/login"/>}
                        </Route>
                        <Route exact path="/login">
                            {!user?.id ? <LoginPage/> : <Redirect to="/"/>}
                        </Route>
                        <Route exact path="/register">
                            {!user?.id ? <RegisterPage/> : <Redirect to="/"/>}
                        </Route>
                        <Route exact path="/logout">
                            {user?.id ? <LogoutPage/> : <Redirect to="/login"/>}
                        </Route>
                        <Route exact path="/confirm-email/:token">
                            <EmailConfirmationPage/>
                        </Route>
                        <Route exact path="/profile" >
                            {user?.id ? <ProfilePage/> : <Redirect to="/login"/>}
                        </Route>
                        <Route exact path="/sessions" >
                            {user?.id ? <SessionsPage/> : <Redirect to="/login"/>}
                        </Route>
                        <Route exact path="/create-request" >
                            {user?.id && user.activated ? <CreateRequestPage/> : <Redirect to="/login"/>}
                        </Route>
                        <Route exact path="/requests" >
                            {user?.id && user.activated ? <RequestListPage/> : <Redirect to="/login"/>}
                        </Route>
                        <Route exact path="/request/:id" >
                            {user?.id && user.activated ? <RequestPage/> : <Redirect to="/login"/>}
                        </Route>
                        <Route component={NotFoundPage}/>
                    </Switch>
                </BrowserRouter>
            </UserContext.Provider>
        </div>
    );
}

export default App;
