import React, {useContext, useState} from 'react';
import RequestContext from '../../context/RequestContext';
import ActionWithConfirmation from '../common/ActionWithConfirmation';
import {RequestStatusTypes} from '../../utils/LeadUtils';
import {setLeadRequestStatusToSubmitted} from '../../api/Lead';
import {isTokenIssue} from '../../utils/ApiUtils';
import UserContext from '../../context/UserContext';

const SubmitSavedRequestBlock = () => {
    const {request, setRequest} = useContext(RequestContext);
    const {tryRefreshTokensAndRepeatAction} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const featureDisabled = request.status !== RequestStatusTypes.REQUEST_SAVED || request.cancellationRequestedAt;
    const setStatusToSubmitted = () => {
        setLoading(true);
        return setLeadRequestStatusToSubmitted(request.link).then(request => {
            setRequest(request);
            setLoading(false);
        }).catch(error =>{
            setLoading(false);
            if (isTokenIssue(error.message))
                throw error;
            else{
                alert('Не удалось отправить заявку');
            }
        });
    };

    const wrappedSetStatusToSubmitted = async () =>{
        try{
            await setStatusToSubmitted();
        }catch (error) {
            setLoading(true);
            await tryRefreshTokensAndRepeatAction(error.message, async ()=>setStatusToSubmitted());
        }
    };

    const determineSubtitle = () => {
        if(request.status !== RequestStatusTypes.REQUEST_SAVED){
            return 'Действие не является актуальным для данной заявки';
        }else if(request.cancellationRequestedAt){
            return 'Действие недоступно: был отправлен запрос на отмену заявки';
        }else{
            return 'Если Вы не планируете прикреплять документы, воспользуйтесь этой функцией';
        }
    };

    return (
        <div>
            <ActionWithConfirmation title='Отправить заявку в А-Лизинг'
                                    subtitle={determineSubtitle()}
                                    confirmationText='Заявка будет отправлена в А-Лизинг. Продолжить?'
                                    actionButtonTitle='Отправить'
                                    disabled={featureDisabled}
                                    loadingText='Отправка заявки...'
                                    loading={loading}
                                    actionToExecute={wrappedSetStatusToSubmitted}
            />
        </div>
    );
};

export default SubmitSavedRequestBlock;