import React, {useContext, useState, useEffect} from 'react';
import * as styles from './Calculator.module.css';
import JoinedTabList from '../form/JoinedTabList';
import {CurrencyTypes} from '../../utils/CurrencyUtils';
import FormInput from '../form/FormInput';
import {getCalculatorConstraints} from '../../api/Calculator';
import {isTokenIssue} from '../../utils/ApiUtils';
import UserContext from '../../context/UserContext';
import CalculatorParamsContext from '../../context/CalculatorParamsContext';
import { useDebouncedCallback } from 'use-debounce';
import MonthlyPaymentPreview from './MonthlyPaymentPreview';
import {ClientTypes} from '../../utils/LeadUtils';

const Calculator = ({setParamsForScheduleRender}) => {
    const [currentConstraints, setCurrentConstraints] = useState(null);
    const [constraints, setConstraints] = useState(null);
    const {tryRefreshTokensAndRepeatAction, user} = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const {calculatorParams, setCalculatorParams} = useContext(CalculatorParamsContext);
    const debouncePeriodMs = 1000;
    const clientTypeLabels = {};
    clientTypeLabels[ClientTypes.PRIVATE] = 'Физ. лицо';
    clientTypeLabels[ClientTypes.BUSINESS] = 'Юр. лицо';
    const algoTabs = [
        {name: 'На уменьшение', value: 'differential'},
        {name: 'Равными платежами', value: 'annuity'}
    ];

    const currencyTabs = Object.keys(CurrencyTypes).map(key => {return {name: key, value: CurrencyTypes[key]}});

    const getConstraints = () => {
        setLoading(true);
        return getCalculatorConstraints().then(newConstraints => {
            setErrorMessage(null);
            setLoading(false);
            if(newConstraints?.length){
                setConstraints(newConstraints);
            }else{
                setErrorMessage('Калькулятор недоступен: параметры ограничений не найдены');
            }
        }).catch(error =>{
            setLoading(false);
            if (isTokenIssue(error.message))
                throw error;
            else{
                setErrorMessage(error.message);
            }
        });
    };

    const wrappedGetConstraints = async () =>{
        try{
            await getConstraints();
        }catch (error) {
            setLoading(true);
            tryRefreshTokensAndRepeatAction(error.message, ()=>getConstraints());
        }
    };

    useEffect(()=>{

        wrappedGetConstraints();
    }, []);

    useEffect(()=>{
        updateConstraints();
    }, [calculatorParams.clientType, calculatorParams.currency, constraints]);

    useEffect(()=>{
        setParamsForScheduleRender(null);
        handleDebouncedInputChange();
    }, [calculatorParams]);

    const updateConstraints = () =>{
        const foundConstraints = constraints?.find(c => c.currency === calculatorParams.currency && c.clientType === calculatorParams.clientType);
        if (foundConstraints){
            setErrorMessage(null);
            setCurrentConstraints(foundConstraints);
        }else{
            if(constraints?.length){
                setCurrentConstraints(null);
                setErrorMessage('Для выбранной валюты и типа клиента не найдены параметры ограничений калькулятора');
            }
        }
    };

    const updateInput = (event) => {
        calculatorParams[event.target.name] = event.target.value;
        setCalculatorParams({...calculatorParams});
    };

    const updateTabInput = (inputName, index, values) => {
        calculatorParams[inputName] = values[index].value;
        setCalculatorParams({...calculatorParams});
    };

    const validateTermMonth = () => {
        if(!currentConstraints){
            return false;
        }
        const v = parseInt(calculatorParams.termMonths);
        const min = parseInt(currentConstraints.minTermMonths);
        const max = parseInt(currentConstraints.maxTermMonths);
        return v >= min && v <= max;
    };

    const validatePrepayPercentage = () => {
        if(!currentConstraints){
            return false;
        }
        const v = parseFloat(calculatorParams.prepayPercentage);
        const min = parseFloat(currentConstraints.minPrepayPercentage);
        const max = parseFloat(currentConstraints.maxPrepayPercentage);
        return v >= min && v <= max;
    };

    const validatePrice = () => {
        const v = parseFloat(calculatorParams.price);
        return v > 0 && /^([0-9]{1,10})([.][0-9]{1,2})?$/.test(calculatorParams.price);
    };

    const handleDebouncedInputChange = useDebouncedCallback(() => {
        if(validatePrice() && validatePrepayPercentage() && validateTermMonth()){
            setParamsForScheduleRender({...calculatorParams});
        }
    }, debouncePeriodMs);

    return (
        <div>
            <form className={styles.calculatorForm}>
                <div className={styles.inputsRow}>
                    <div className={styles.inputContainer}>
                        <h4 className={styles.subHeader}>Вариант расчета</h4>
                        <JoinedTabList className={styles.tabInput} tabs={algoTabs} activeTabIndex={algoTabs.findIndex(t => t.value === calculatorParams.algo)}
                                       onTabClick={(tabIndex) =>{updateTabInput('algo', tabIndex, algoTabs)}} />
                    </div>
                </div>


                <div className={styles.inputsRow}>
                    <div className={styles.inputContainer}>
                        <h4 className={styles.subHeader}>Стоимость авто, с НДС</h4>
                        <FormInput className={styles.regularInput} name='price' placeholder='20000' type='float' value={calculatorParams.price}
                                   valid={validatePrice()} touched={calculatorParams.price !== null}
                                   onChange={updateInput} maxLength={13}/>
                    </div>
                    <div className={styles.inputContainer}>
                        <h4 className={styles.subHeader}>Валюта</h4>
                        <JoinedTabList className={styles.tabInput} tabs={currencyTabs} activeTabIndex={currencyTabs.findIndex(t => t.value === calculatorParams.currency)} onTabClick={(tabIndex) =>{updateTabInput('currency', tabIndex, currencyTabs)}} />
                    </div>
                </div>

                <div className={styles.inputsRow}>
                    <div className={styles.inputContainer}>
                        <h4 className={styles.subHeader}>
                            <span>Аванс </span>
                            {currentConstraints ? <span className={styles.rangeLabel}>({currentConstraints.minPrepayPercentage} - {currentConstraints.maxPrepayPercentage}%)</span> : ''}
                        </h4>
                        <FormInput className={styles.regularInput} name='prepayPercentage' placeholder='25.5' type='float' value={calculatorParams.prepayPercentage}
                                   valid={validatePrepayPercentage()} touched={calculatorParams.prepayPercentage !== null}
                                   onChange={updateInput} maxLength={6}/>
                    </div>
                    <div className={styles.inputContainer}>
                        <h4 className={styles.subHeader} style={{marginBottom: '10px'}}>Ежемесячный платёж</h4>
                        <MonthlyPaymentPreview/>
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <h4 className={styles.subHeader}>
                        <span>Ожидаемый срок лизинга </span>
                        {currentConstraints ? <span className={styles.rangeLabel}>({currentConstraints.minTermMonths} - {currentConstraints.maxTermMonths} месяцев)</span> : ''}
                    </h4>
                    <FormInput className={styles.regularInput} name='termMonths' placeholder='12' type='number' value={calculatorParams.termMonths}
                               valid={validateTermMonth()} touched={calculatorParams.termMonths !== null}
                               onChange={updateInput} maxLength={2}/>
                </div>
            </form>
            {constraints?.length ? <div className={styles.constraintsDisclaimerText}>
                <span className={styles.constraintsLink}>
                    Параметры калькулятора
                    <span className={styles.constraintsList}>
                        {constraints.map((c, index)=> <div key={index} className={`${styles.constraintsListItem} ${c.currency === currentConstraints?.currency
                                                                                                                    && c.clientType === currentConstraints?.clientType ?
                                                                                                                    styles.selected : ''}`}>
                                <div className={styles.constraintTitle}>{c.currency} + {clientTypeLabels[c.clientType]}:</div>
                                <div className={styles.constraintValues}>
                                    <div className={styles.constraintValueItem}>Аванс: {c.minPrepayPercentage}-{c.maxPrepayPercentage}%</div>
                                    <div className={styles.constraintValueItem}>Срок: {c.minTermMonths}-{c.maxTermMonths} мес.</div>
                                </div>
                            </div>
                        )}
                    </span>
                </span>
                загружены для дилера
                <span className={styles.dealerName}>«{user.dealer?.name}»</span></div> : ''}
                {!constraints?.length && user.dealer ? <div className={styles.constraintsDisclaimerText}>Поиск параметров калькулятора осуществляется для дилера «{user.dealer?.name}»</div>: ''}
                <div className={styles.errorMessage}>{errorMessage}</div>
        </div>
    );
};

export default Calculator;