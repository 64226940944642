import React, {useContext, useState, useEffect} from 'react';
import * as styles from './NewDealForm.module.css';
import FileDropInput from '../form/FileDropInput';
import LargeButton from '../common/LargeButton';
import FileItem from '../common/FileItem';
import {createDeal, getRequiredDealAttachments} from '../../api/Lead';
import {isTokenIssue} from '../../utils/ApiUtils';
import RequestContext from '../../context/RequestContext';
import UserContext from '../../context/UserContext';
import LoadingIndicator from '../common/LoadingIndicator';
import WhiteBox from '../common/WhiteBox';

const NewDealForm = ({onCancelClick}) => {

    const [attachedFiles, setAttachedFiles] = useState({});
    const [requiredFiles, setRequiredFiles] = useState([]);
    const {request, setRequest} = useContext(RequestContext);
    const {tryRefreshTokensAndRepeatAction} = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const getRequiredFiles = () => {
        setLoading(true);
        return getRequiredDealAttachments().then(newConstraints => {
            setLoading(false);
            setRequiredFiles(newConstraints);
        }).catch(error =>{
            setLoading(false);
            if (isTokenIssue(error.message))
                throw error;
            else{
                alert('Форма недоступна: не удалось получить список необходимых файлов');
            }
        });
    };

    const wrappedGetRequiredFiles = async () =>{
        try{
            await getRequiredFiles();
        }catch (error) {
            setLoading(true);
            tryRefreshTokensAndRepeatAction(error.message, ()=>getRequiredFiles());
        }
    };

    useEffect(() => {
        wrappedGetRequiredFiles();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(!checkIfAllRequiredFilesAttached()){
            return
        }
        const formData = new FormData();
        requiredFiles.forEach(file => {
            formData.append(file.name, attachedFiles[file.name]);
        });
        try{
            await submitDeal(formData);
        }catch (error) {
            setLoading(true);
            await tryRefreshTokensAndRepeatAction(error.message, async ()=>submitDeal(formData));
        }
    };

    const submitDeal = (formData) => {
        setLoading(true);
        return createDeal(request.link, formData).then(request => {
            setRequest(request);
            setLoading(false);
        }).catch(error =>{
            setLoading(false);
            if (isTokenIssue(error.message))
                throw error;
            else{
                alert(error.message);
            }
        });
    };

    const handleFileInputChange = (fileName, filesArray) => {
        attachedFiles[fileName] = filesArray.length ? filesArray[0] : null;
        setAttachedFiles({...attachedFiles});
    };

    const checkIfAllRequiredFilesAttached = () => {
         return requiredFiles.every(file => !!attachedFiles[file.name]);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <h3 className={styles.title}>Прикрепить документы</h3>
            <WhiteBox>
                <div className={styles.sampleContainer}>
                    <h4 className={styles.subtitle}>Файл для скачивания, содержащий все необходимые документы</h4>
                    <FileItem name='Анкета+Согласие' link={`${process.env.PUBLIC_URL}/deal_sample.xlsx`} className={styles.sampleFile} isForDownload/>
                </div>
            </WhiteBox>

            {requiredFiles.map((f, key) =>
                 <div key={key}>
                    <h4 className={styles.subtitle}>{f.label}</h4>
                    <p className={styles.comment}>{f.comment}</p>
                    <FileDropInput onFilesChange={filesArray => handleFileInputChange(f.name, filesArray)} acceptedExt={f.acceptedExtensions}/>
                </div>
            )}

            <div className={styles.buttonsContainer}>
                <LargeButton filled={false} onClick={onCancelClick}>Отмена</LargeButton>
                <LargeButton type='submit' disabled={!checkIfAllRequiredFilesAttached()}>Отправить</LargeButton>
            </div>
            <LoadingIndicator shown={loading}  style={{top: '-5px', left: '-5px', right: '-5px', bottom: '-5px'}}/>
        </form>
    );
};

export default NewDealForm;