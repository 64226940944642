import React, {useContext, useState, useEffect} from 'react';
import FormInput from '../form/FormInput';
import * as styles from './NewRequestForm.module.css';
import WhiteBox from '../common/WhiteBox';
import TabList from '../form/TabList';
import LoadingIndicator from '../common/LoadingIndicator';
import ArrowButton from '../common/ArrowButton';
import {submitLeadRequest} from '../../api/Lead';
import {isTokenIssue} from '../../utils/ApiUtils';
import UserContext from '../../context/UserContext';
import LargeButton from '../common/LargeButton';
import {ClientTypes} from '../../utils/LeadUtils';
import {CurrencyTypes} from '../../utils/CurrencyUtils';
import JoinedTabList from '../form/JoinedTabList';
import CalculatorParamsContext from '../../context/CalculatorParamsContext';
import {UserRoles} from '../../utils/UserUtils';
import Calculator from '../calculator/Calculator';
import PaymentSchedule from '../calculator/PaymentSchedule';
import PaymentScheduleContext from '../../context/PaymentScheduleContext';


const NewRequestForm = ({successfulSubmitCallback, withCalculator}) => {
    const {user, tryRefreshTokensAndRepeatAction} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const formStatusTypes = {NONE: 0, SUCCESS: 1, FAIL: 2};
    const [formStatus, setFormStatus] = useState(formStatusTypes.NONE);
    const [lastSubmitError, setLastSubmitError] = useState('');
    const {calculatorParams, setCalculatorParams} = useContext(CalculatorParamsContext);
    let [paramsForScheduleRender, setParamsForScheduleRender] = useState({...calculatorParams});
    const [clientTypeIndex, setClientTypeIndex] = useState(0);
    const clientTypeTabs = [{name: 'Физическое лицо', value: ClientTypes.PRIVATE}, {name: 'Юридическое лицо', value: ClientTypes.BUSINESS}];
    const currencies = Object.keys(CurrencyTypes).map(key => {return {name: key, value: CurrencyTypes[key]}});
    const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(0);

    const {paymentSchedule} = useContext(PaymentScheduleContext);

    const initialFormInputs = {
        surname: {value: '', valid: false, touched: false},
        name: {value: '', valid: false, touched: false},
        patronymic: {value: '', valid: true, touched: false},
        phone: {value: '', valid: false, touched: false},
        companyName: {value: '', valid: false, touched: false, clientTypes: [ClientTypes.BUSINESS]},
        payerAccountNumber: {value: '', valid: false, touched: false, clientTypes: [ClientTypes.BUSINESS]},
        carName: {value: '', valid: false, touched: false},
        price: {value: '', valid: false, touched: false},

    };
    const [formInputs, setFormInputs] = useState(initialFormInputs);

    const getCleanPhone = (formattedPhone) => {
        return formattedPhone.replace(/\D/g, '')
    };

    const validators = {
        name: (value) => {
            return value.length > 0;
        },
        surname: (value) => {
            return value.length > 0;
        },
        password: (value) => {
            return value.length >= 6;
        },
        email: (value) => {
            return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value);
        },
        payerAccountNumber: (value) => {
            return /^[\d]{9}$/.test(value);
        },
        phone: (value) => {
            return /^[\d]{9}$/.test(getCleanPhone(value));
        },
        price: (value) => {
            return /^([0-9]{1,10})([.][0-9]{1,2})?$/.test(value);
        }
    };

    useEffect(()=>{
        if(withCalculator){
            setFormInputs({...formInputs, price: {value: calculatorParams.price, valid: validators.price(calculatorParams.price), touched: true}})
        }
    },[calculatorParams.price]);

    useEffect(()=>{
        if(withCalculator){
            const index = currencies.findIndex(c => c.value === calculatorParams.currency);
            setSelectedCurrencyIndex(index);
        }
    },[calculatorParams.currency]);


    let handleInputChange = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
        const isValid = validators[name] ? validators[name](value) : true;
        setFormInputs({...formInputs, [name]: {...formInputs[name], value: value, valid: isValid, touched: true}});
        setFormStatus(formStatusTypes.NONE);
    };

    const handleSubmit = async () => {
        const filteredInputs = filterInputsBySelectedClientType(formInputs);
        let allFieldsValid = validateAllLeadFields();
        if (allFieldsValid){
            const dataToSubmit = {};
            for (let key in filteredInputs){
                if(filteredInputs.hasOwnProperty(key)){
                    dataToSubmit[key] = filteredInputs[key].value;
                }
            }
            try {
                dataToSubmit['phone'] = `+375${getCleanPhone(dataToSubmit['phone'])}`;
                dataToSubmit['clientType'] = clientTypeTabs[clientTypeIndex].value;
                dataToSubmit['currency'] = currencies[selectedCurrencyIndex].value;
                if(withCalculator){
                    if (paramsForScheduleRender){
                        dataToSubmit['calculatorParams'] = calculatorParams;
                    }
                }
                setLoading(true);
                wrappedCreateLeadRequest(dataToSubmit)
                    .then(processSuccessfulSubmit)
                    .catch(processFailedSubmit);
            }catch (e) {
                processFailedSubmit(e);
            }
        }else{
            markInvalidFieldsAsTouched();
        }
    };

    const validateAllLeadFields = (filteredInputs) => {
        filteredInputs = filteredInputs ? filteredInputs : filterInputsBySelectedClientType(formInputs);
        return Object.keys(filteredInputs).every(key => formInputs[key].valid === true);
    };

    const wrappedCreateLeadRequest = async (dataToSubmit)=> {
        try{
            return await submitLeadRequest(dataToSubmit);
        }catch (error) {
            if (isTokenIssue(error.message))
                return tryRefreshTokensAndRepeatAction(error.message, () => submitLeadRequest(dataToSubmit));
            else{
                throw error;
            }
        }
    };

    const filterInputsBySelectedClientType = () => {
        return Object.fromEntries(Object.entries(formInputs).filter(([key, value]) => (!value.clientTypes || value.clientTypes.indexOf(clientTypeTabs[clientTypeIndex].value) !== -1)));
    };

    const handleClientTypeChange = (selectedClientTypeIndex) => {
        setClientTypeIndex(selectedClientTypeIndex);
        setCalculatorParams({...calculatorParams, clientType: clientTypeTabs[selectedClientTypeIndex].value});
    };

    const handleCurrencyChange = (currencyIndex) => {
        setSelectedCurrencyIndex(currencyIndex);
    };

    let processSuccessfulSubmit = (newRequest) => {
        setLoading(false);
        setFormInputs(initialFormInputs);
        setFormStatus(formStatusTypes.SUCCESS);
        successfulSubmitCallback(newRequest);
    };

    let processFailedSubmit = (error) => {
        setFormStatus(formStatusTypes.FAIL);
        setLastSubmitError(error.message);
        setLoading(false);
        if (error instanceof TypeError) {
            alert('Проверьте подключение к интернету.');
        }
    };

    let markInvalidFieldsAsTouched = () => {
        let formDataCopy = {...formInputs};
        for (let [key, value] of Object.entries(formDataCopy)){
            if(!value.valid)
                value.touched = true;
        }
        if(withCalculator && !formInputs.price.value){
            setCalculatorParams({...calculatorParams, price: ''})
        }
        setFormInputs(formDataCopy);
    };


    return (
        <div>
            <form className={styles.form}>
                <TabList tabs={clientTypeTabs}
                         activeTabIndex={clientTypeIndex} onTabClick={handleClientTypeChange} />
                <h4 className={styles.subHeader}>ФИО клиента</h4>
                <div className={styles.inputRow}>
                    <FormInput name='surname' placeholder='Фамилия' type='text' value={formInputs.surname.value}
                               valid={formInputs.surname.valid} touched={formInputs.surname.touched}
                               onChange={handleInputChange} maxLength={48}/>
                    <FormInput name='name' placeholder='Имя' type='text' value={formInputs.name.value}
                               valid={formInputs.name.valid} touched={formInputs.name.touched}
                               onChange={handleInputChange} maxLength={48}/>
                    <FormInput name='patronymic' placeholder='Отчество (если есть)' type='text' value={formInputs.patronymic.value}
                               valid={formInputs.patronymic.valid} touched={formInputs.patronymic.touched}
                               onChange={handleInputChange} maxLength={48}/>
                </div>

                <h4 className={styles.subHeader}>Номер телефона</h4>
                <FormInput name='phone' placeholder='(__) ___-__-__' type='phone' value={formInputs.phone.value}
                           valid={formInputs.phone.valid} touched={formInputs.phone.touched}
                           onChange={handleInputChange} maxLength={14}/>

                {clientTypeTabs[clientTypeIndex].value === ClientTypes.BUSINESS ?
                    <>
                        <h4 className={styles.subHeader}>Организация</h4>
                        <div className={styles.inputRow}>
                            <FormInput name='companyName' placeholder='Название организации' type='text' value={formInputs.companyName.value}
                                       valid={formInputs.companyName.valid} touched={formInputs.companyName.touched}
                                       onChange={handleInputChange} maxLength={48}/>
                            <FormInput name='payerAccountNumber' placeholder='УНП' type='number' value={formInputs.payerAccountNumber.value}
                                       valid={formInputs.payerAccountNumber.valid} touched={formInputs.payerAccountNumber.touched}
                                       onChange={handleInputChange} maxLength={9}/>
                        </div>
                    </>
                : ''}

                <h4 className={styles.subHeader}>Желаемый автомобиль</h4>
                <FormInput name='carName' placeholder='Бренд и модель' type='text' value={formInputs.carName.value}
                           valid={formInputs.carName.valid} touched={formInputs.carName.touched}
                           onChange={handleInputChange} maxLength={64}/>

                {!withCalculator ?
                    <>
                        <div  className={styles.inputRow}>
                            <FormInput name='price' placeholder='Стоимость' type='float' value={formInputs.price.value}
                                       valid={formInputs.price.valid} touched={formInputs.price.touched}
                                       onChange={handleInputChange} maxLength={13}/>
                            <JoinedTabList className={styles.currencyInput} tabs={currencies}
                                           activeTabIndex={selectedCurrencyIndex} onTabClick={handleCurrencyChange} />
                        </div>
                    </>
                : ''}

                <LoadingIndicator shown={loading} style={{top: '-5px', left: '-5px', right: '-5px', bottom: '-5px'}}/>
            </form>
            {withCalculator ? <Calculator setParamsForScheduleRender={setParamsForScheduleRender}/> : ''}
            {formStatus === formStatusTypes.FAIL ? <div className={styles.errorContainer}>{lastSubmitError}</div> : ''}
            <div className={styles.buttonContainer}>
                {withCalculator ? <LargeButton filled={false} onClick={() => window.print()} disabled={!paymentSchedule}>Распечатать график</LargeButton> : ''}
                <LargeButton onClick={handleSubmit} disabled={!validateAllLeadFields()}>{(user.role === UserRoles.DEALER || user.role === UserRoles.TEST) ? 'Сохранить' : 'Отправить'} {withCalculator && validateAllLeadFields() ? '*' : ''}</LargeButton>
            </div>
            {withCalculator && validateAllLeadFields() ?
                 <div className={styles.scheduleAttachmentDisclaimer}>
                     {paramsForScheduleRender ? '* график платежей будет прикреплён к заявке' : '* к заявке не будет прикреплён график платежей (заполнены не все поля)'}
                 </div>
             : ''}
            {withCalculator ? <PaymentSchedule params={paramsForScheduleRender}/> : ''}
        </div>
    );
};

export default NewRequestForm;