import React, {useState} from 'react';
import LargeButton from '../common/LargeButton';
import WhiteBox from '../common/WhiteBox';
import * as styles from './ActionWithConfirmation.module.css';
import LoadingIndicator from './LoadingIndicator';
const ActionWithConfirmation = ({title, subtitle, actionButtonTitle, confirmationText, disabled, loading, loadingText, actionToExecute}) => {
    const [step, setStep] = useState(0);
    const confirmationHandler = () =>{
        setStep(0);
        actionToExecute();
    };
    return (
        <div className={styles.wrapper}>
            <LoadingIndicator shown={loading} withText={loadingText} />
            <WhiteBox>
                <h4 className={styles.title}>{title}</h4>
                {step === 0 ?
                    <>
                        <p className={styles.text}>{subtitle}</p>
                        <LargeButton disabled={disabled} className={styles.firstStepButton} filled={false} onClick={() => setStep(1)}>{actionButtonTitle}</LargeButton>
                    </>
                    : ''}
                {step === 1 ?
                    <>
                        <p className={styles.text}>{confirmationText}</p>
                        <div className={styles.confirmationButtonsContainer}>
                            <LargeButton className={styles.cancelButton} filled={false} onClick={() => setStep(0)}>Отмена</LargeButton>
                            <LargeButton onClick={confirmationHandler} disabled={disabled || loading} className={styles.confirmButton} filled={true}>Продолжить</LargeButton>
                        </div>
                    </>
                    : ''}
            </WhiteBox>
        </div>
    );
};

export default ActionWithConfirmation;